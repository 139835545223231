<template>
  <div id="home" :key="refresh">
    <!-- seccion1 -->
    <div class="parallax">
      <p class="text-white banner_title div-a1 animate__animated animate__fadeInUp ">
        {{ textos.seccion_1_texto_banner }}
      </p>
    </div>

    <div class="mt-5 mx-md-0 div1" style="">
      <div class="row p-3 m-5">
        <img
          class="col-4 p-2 icon-mv"
          width="40px"
          src="/img/icons/Airbnbscuadrado.png"
          alt=""
        />
        <img
          class="col-4 p-2 icon-mv"
          width="40px"
          src="/img/icons/Bookingcuadrado.png"
          alt=""
        />
        <img
          class="col-4 p-2 icon-mv"
          src="/img/icons/Vrbocuadrado.png"
          alt=""
        />
        <img
          class="col-4 p-2 icon-mv"
          src="/img/icons/Trapadvisorcuadrado.png"
          alt=""
        />
        <img
          class="col-4 p-2 icon-mv"
          src="/img/icons/expediacuadrado.png"
          alt=""
        />
        <img
          class="col-4 p-2 icon-mv"
          src="/img/icons/agodacuadrado.png"
          alt=""
        />
      </div>

      <p class="title px-3 " style="text-align: center !important;">{{ textos.seccion_1_titulo }}</p>
      <p class="text-2 h1 mt-3 px-3" style="text-align: center !important;">{{ textos.seccion_1_subtitulo }}</p>
      <!-- fin seccion 1 -->
      <!-- seccion2 -->
      <div class="row m-0 p-0">
        <ficha_gestion
          v-for="it0 in fichas_gestion_seccion_2"
          :key="it0.id"
          :data="it0"
        />
      </div>
      <!-- fin seccion 2 -->
      <!-- seccion3 -->
      <div id="Limpieza" class="p-md-5 mt-md-5 mx-md-5 mt-4 mb-5 px-3">
        <div class="row px-3">
          <card v-for="it1 in cards_seccion_3" :key="it1.id" :data="it1" />
        </div>
      </div>
      <!-- fin seccion 3 -->
      <div class="parallax5 px-0 mx-0">
        <p class="text-white h1 div-a2" data-aos="fade-up">
          {{ textos.seccion_10_titulo }}
        </p>
      </div>
      <div
        id="beneficios"
        class="mb-2 py-5 my-2 div-3 px-md-0 px-3 container text-left"
        style=""
      >
        <p class="">
          {{ textos.seccion_10_descripcion_parte_1 }}
        </p>
        <p class="">
          {{ textos.seccion_10_descripcion_parte_2 }}
        </p>
      </div>
      <div id="Nuestros_servicio" class="parallax5 parallax_nuestros_serv px-0 mx-0">
        <p class="text-white h1 div-a2" data-aos="fade-up">
          {{ textos.nuestros_servicios }}
        </p>
      </div>
      <!-- NUESTROS SERVICIOS -->
      <!-- <span class="title ">{{ textos.nuestros_servicios }}</span> -->
      <div class="px-3 mt-3" >
        <div
          v-if="cards_nuestros_servicios.length > 0"
          
          class="container pt-md-0 pt-4 mb-5"
        >
          <div  class="row mb-5">
            <!-- <card v-for="it2 in cards_nuestros_servicios" :key="it2" :data="it2" /> -->
            <card_sistem_colab0 :data="cards_nuestros_servicios[0]" />
            <card_sistem_colab1 :data="cards_nuestros_servicios[1]" />
            <!-- <card :data="cards_nuestros_servicios[1]" /> -->
          </div>
        </div>
      </div>

      <!-- fin -->
    </div>
    <!-- seccion4 -->
    <div class="parallax2">
      <p class="text-white h1 div-a2" data-aos="fade-up">
        {{ textos.seccion_4_texto_banner }}
      </p>
    </div>
    <!-- fin seccion 4 -->
    <!-- seccion5 -->
    <div id="Chekin" class="mb-3 div2 px-md-0 px-3" style="">
      <div class="container">
        <div class="row">
          <card
            v-for="it3 in cards_seccion_5"
            class="my-5"
            :key="it3.id"
            :data="it3"
          />
        </div>
      </div>
    </div>
    <!-- fin seccion 5 -->

    <!-- antes era seccion 12 check in remoto-->

    <!-- seccion12 -->
    <div id="Intervencion" class="parallax6 text-right mb-0 pb-4">
      <p class="text-white h1 div-a2" data-aos="fade-up">
        {{ textos.seccion_12_titulo }}
      </p>
      <!-- <div class="mt-4" data-aos="fade-up">
        <p class="text-white p-a4">
          {{ textos.seccion_8_descripcion_parte_1 }}
        </p>
        <p class="text-white p-a4">
          {{ textos.seccion_8_descripcion_parte_2 }}
        </p>
        <p class="text-white p-a4">
          {{ textos.seccion_8_descripcion_parte_3 }}
        </p>
      </div> -->
    </div>

    <div class="my-3 py-3 div4 container text-left px-3 px-md-0">
      <div class="mt-4" data-aos="fade-up">
        <p class="">
          {{ textos.seccion_12_descripcion_parte_1 }}
        </p>
        <p class="mb-0 pb-4">
          {{ textos.seccion_12_descripcion_parte_2 }}
        </p>
      </div>
    </div>

    <!-- fin antes era seccion 12 check in remoto-->

    <!-- seccion6 -->
    <div id="Reparacion" class="parallax3 text-center">
      <p class="text-white h1 div-a2" data-aos="fade-up">
        {{ textos.seccion_6_texto_banner }}
      </p>
    </div>

    <!-- fin seccion 6 -->
    <!-- seccion7 -->
    <div class="my-5 div3">
      <div class="titulo-reparacion">
        <span class="h2">{{ textos.seccion_7_titulo }} </span
        ><span class="h4 text-3">{{ textos.seccion_7_subtitulo }}</span>
      </div>
      <div class="container">
        <p class="text-justify mt-3 text-3 mt-3">
          {{ textos.seccion_7_descripcion }}
        </p>
        <div class="text-left text-3">
          <span>{{ textos.seccion_7_item_list_1 }}<br /></span>
          <span>{{ textos.seccion_7_item_list_2 }}<br /></span>
          <span>{{ textos.seccion_7_item_list_3 }}<br /></span>
          <span>{{ textos.seccion_7_item_list_4 }}<br /></span>
          <span>{{ textos.seccion_7_item_list_5 }}<br /></span>
          <span>{{ textos.seccion_7_item_list_6 }}</span>
          <br /><br />
          <div style="text-align: center">
            <span class="mt-3 final-text-s7">{{
              textos.seccion_7_text_final
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- fin seccion 7 -->
    <!-- seccion8 -->
    <div id="Intervencion" class="parallax4 text-right mb-0 pb-4">
      <p class="text-white h1 div-a2" data-aos="fade-up">
        {{ textos.seccion_8_titulo }}
      </p>
      <!-- <div class="mt-4" data-aos="fade-up">
        <p class="text-white p-a4">
          {{ textos.seccion_8_descripcion_parte_1 }}
        </p>
        <p class="text-white p-a4">
          {{ textos.seccion_8_descripcion_parte_2 }}
        </p>
        <p class="text-white p-a4">
          {{ textos.seccion_8_descripcion_parte_3 }}
        </p>
      </div> -->
    </div>
    <div class="my-3 py-3 px-3 px-md-0 div4 text-left container">
      <div class="mt-4" data-aos="fade-up">
        <p class="">
          {{ textos.seccion_8_descripcion_parte_1 }}
        </p>
        <p class="">
          {{ textos.seccion_8_descripcion_parte_2 }}
        </p>
        <p class="">
          {{ textos.seccion_8_descripcion_parte_3 }}
        </p>
      </div>
    </div>
    <!-- fin seccion 8 -->
    <!-- seccion9 -->
    <div id="Intervencion" class="parallax_lavanderia text-right mb-0 pb-4">
      <p class="text-white h1 div-a2" data-aos="fade-up">
        {{ textos.seccion_9_titulo }}
      </p>
      <!-- <div class="mt-4" data-aos="fade-up">
        <p class="text-white p-a4">
          {{ textos.seccion_8_descripcion_parte_1 }}
        </p>
        <p class="text-white p-a4">
          {{ textos.seccion_8_descripcion_parte_2 }}
        </p>
        <p class="text-white p-a4">
          {{ textos.seccion_8_descripcion_parte_3 }}
        </p>
      </div> -->
    </div>
    <div class="my-2 py-4 px-3 px-md-0 div4">
      <!-- <div class="titulo-reparacion">
        <span class="h2" data-aos="fade-up">{{ textos.seccion_9_titulo }}</span>
      </div> -->
      <div class="container">
        <!-- <div v-html="textos.seccion_9_subtitulo"></div> -->
        <!-- <p class="text-center text-3 " data-aos="fade-up">
          {{ textos.seccion_9_subtitulo }}
        </p> -->
        <div class="d-flex flex-column align-items-start">
          
          <div v-html="textos.seccion_9_item_list_1"></div>
          <div v-html="textos.seccion_9_item_list_2"></div>
          <div v-html="textos.seccion_9_item_list_3"></div>
        </div>
      </div>
    </div>
    <!-- fin seccion 9 -->
    <!-- seccion10 -->
    <!--  -->
    <!-- fin seccion 10 -->
    <!-- seccion11 -->
    <div id="Intervencion" class="serv_integral text-right mb-0 pb-4">
      <p class="text-white h1 div-a2" data-aos="fade-up">
        {{ textos.seccion_11_titulo }}
      </p>
      <!-- <div class="mt-4" data-aos="fade-up">
        <p class="text-white p-a4">
          {{ textos.seccion_8_descripcion_parte_1 }}
        </p>
        <p class="text-white p-a4">
          {{ textos.seccion_8_descripcion_parte_2 }}
        </p>
        <p class="text-white p-a4">
          {{ textos.seccion_8_descripcion_parte_3 }}
        </p>
      </div> -->
    </div>
    <div class="my-3 py-4 div4 container px-3 px-md-0">
      <div class="row m-0 px-0 px-md-0">
        <div class="col-xm-6 col-md-6 px-md-0 div_res_serv-inte_pc">
          <!-- <div class="text-position-div-5 w-titulo-5">
            <span class="h1">{{ textos.seccion_11_titulo }}</span>
          </div> -->

          <p class="text-3 text-position-div-5-p text-left mt-3">
            {{ textos.seccion_11_descripcion }}
          </p>
        </div>
        <div class="col-xm-6 col-md-6 div_res_serv-inte_pc">
          <div class="text-center"> 
            <div class="row ml-md-4 mt-4 w-lista-5">
              <div class="col-md-6 pr-md-3 pr-0 pl-md-0 pl-4 ml-md-0 text-left ">
                <p>
                  <b> {{ textos.seccion_11_item_list_1 }}</b>
                </p>
                <p>
                  <b> {{ textos.seccion_11_item_list_2 }}</b>
                </p>
                <p>
                  <b> {{ textos.seccion_11_item_list_3 }}</b>
                </p>
                <p>
                  <b> {{ textos.seccion_11_item_list_4 }}</b>
                </p>
              </div>
              <div class="col-md-6 pr-md-3 pr-0 pl-md-0 pl-4 ml-md-0 text-left ">
                <p>
                  <b>{{ textos.seccion_11_item_list_5 }}</b>
                </p>
                <p>
                  <b>{{ textos.seccion_11_item_list_6 }}</b>
                </p>
                <p>
                  <b>{{ textos.seccion_11_item_list_7 }}</b>
                </p>
                <p>
                  <b>{{ textos.seccion_11_item_list_8 }}</b>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 div_res_serv-inte_movil">
          <!-- <div class="text-position-div-5 w-titulo-5">
            <span class="h1">{{ textos.seccion_11_titulo }}</span>
          </div> -->

          <p class="text-3 text-position-div-5-p text-left mt-3">
            {{ textos.seccion_11_descripcion }}
          </p>
        </div>
        <div class="col-12 div_res_serv-inte_movil">
          <div class="text-center">
            <div class="row ml-md-4 mt-4 w-lista-5">
              <div class="col-md-6 pr-md-3 pr-0 pl-md-0 pl-4 ml-md-0 text-left padding_0">
                <p>
                  <b> {{ textos.seccion_11_item_list_1 }} </b>
                </p>
                <p>
                  <b> {{ textos.seccion_11_item_list_2 }}</b>
                </p>
                <p>
                  <b> {{ textos.seccion_11_item_list_3 }}</b>
                </p>
                <p>
                  <b> {{ textos.seccion_11_item_list_4 }}</b>
                </p>
              </div>
              <div class="col-md-6 pr-md-3 pr-0 pl-md-0 pl-4 ml-md-0 text-left padding_0">
                <p>
                  <b>{{ textos.seccion_11_item_list_5 }}</b>
                </p>
                <p>
                  <b>{{ textos.seccion_11_item_list_6 }}</b>
                </p>
                <p>
                  <b>{{ textos.seccion_11_item_list_7 }}</b>
                </p>
                <p>
                  <b>{{ textos.seccion_11_item_list_8 }}</b>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- fin seccion 11 -->

    <!-- <div class="parallax6 text-right" id="Contacto">
      <p class="text-white h1 div-a2" data-aos="fade-up">
        {{ textos.seccion_12_titulo }}
      </p>
      <div class="mt-4" data-aos="fade-up">
        <p class="text-white p-a6 ">
          {{ textos.seccion_12_descripcion_parte_1 }}
        </p>
        <p class="text-white p-a6 mb-0 pb-4">
          {{ textos.seccion_12_descripcion_parte_2 }}
        </p>
      </div>
    </div> -->
    <!-- fin seccion 12 -->
  </div>
</template>

<script>
import ficha_gestion from "../components/ficha_gestion.vue";
import card from "../components/card.vue";
import card_sistem_colab0 from "../components/card_sistem_colab0.vue";
import card_sistem_colab1 from "../components/card_sistem_colab1.vue";
export default {
  components: { ficha_gestion, card, card_sistem_colab0, card_sistem_colab1 },
  data() {
    return {
      email: "",

      textos: {
        // seccion_1_texto_banner:'“Somos una empresa especializada en la gestión de apartamentos turísticos ofreciendo la mayor rentabilidad por su propiedad.”',
        // seccion_1_titulo:'¿Anuncias tu casa en alquiler en Airbnb, Booking, Expedia, HomeAway, Stayz…?',
        // seccion_1_subtitulo:'Nosotros los gestionamos por ti:',
        // seccion_4_texto_banner:'Checkin / Checkout',
        // seccion_6_texto_banner:'REPARACION DE URGENCIAS DE MANTENIMIENTO DEL APARTAMENTO',
        // seccion_7_titulo:'REPARACION DE URGENCIAS DE MANTENIMIENTO DEL APARTAMENTO.',
        // seccion_7_subtitulo:'(PRECIOS SEGÚN TRABAJO SOLICITADO)',
        // seccion_7_descripcion:'Este nuevo servicio se ha vuelto indispensable como los antes mencionados, ya que desde pintar o cambiar una simple tubería es esenciales para hacer una estadía agradable para sus huéspedes.Lamentablemente, a veces sus huésped se ven afectados por fallos en las instalaciones, muchas veces causados por el inevitable desgaste del uso, o por una inadecuada utilización de los elementos. Es por ellos que le ofrecemos las siguientes ayudas:',
        // seccion_7_item_list_1:'- Fontanería.',
        // seccion_7_item_list_2:'- Servicio de pintura.',
        // seccion_7_item_list_3:'- Servicio de Electricidad.',
        // seccion_7_item_list_4:'- Califacción.',
        // seccion_7_item_list_5:'- Reposición de objetos rotos en el mismo (solicitud por el cliente).',
        // seccion_7_text_final:'y todo tipo de reparación que se pueda presentar en el apartamento. Precios según trabajo solicitado.',
        // seccion_8_titulo:'INTERVENCIÓN EN CASO DE RUIDOS Y/O MOLESTIAS EXTERNAS O INTERNAS CAUSADAS.',
        // seccion_8_descripcion_parte_1:'Mantener un cliente satisfecho requiere un gran esfuerzo que puede perderse en un pequeño descuido.',
        // seccion_8_descripcion_parte_2:'Es por ello que cualquier incidencia con las instalaciones de un apartamento es una mala experiencia que el huésped recibirá con mucho disgusto.',
        // seccion_8_descripcion_parte_3:'Evite perder clientes contando con esta ayuda. Precios según horario solicitado por el cliente.',
        // seccion_9_titulo:'SERVICIO DE LAVANDERÍA',
        // seccion_9_subtitulo:'Servicio de lavandería en el que se incluyen:',
        // seccion_9_item_list_1:'* Lavado y planchado de ropa de cama.',
        // seccion_9_item_list_2:'* Lavado de toallas grandes y pequeñas',
        // seccion_9_item_list_3:'* Servicio de transporte para la mismas.',
        // seccion_10_titulo:'BENEFICIOS DE NUESTROS CLIENTES',
        // seccion_10_descripcion_parte_1:'Contamos con un equipo de profesionales para dar el mejor cuidado a sus huéspedes, daremos respuestas a las consultas y limpiaremos su apartamento.',
        // seccion_10_descripcion_parte_2:'Libre de todo el trabajo que requiera su apartamento, nuestro equipo se encargará, atención 24/7, el check-in/check-out, preparación del apartamento para los siguientes huéspedes.',
        // seccion_11_titulo:'Te ofrecemos un servicio integral con los mejores colaboradores',
        // seccion_11_descripcion:'Te lo ponemos fácil para iniciar el alquiler turístico, con una amplia red de colaboradores locales que ofrecen un servicio completo de puesta en marcha de tu vivienda de uso turístico.',
        // seccion_11_item_list_1:'Asesoramiento personalizado',
        // seccion_11_item_list_2:'Proyecto de arquitectura',
        // seccion_11_item_list_3:'Asesoramiento fiscal',
        // seccion_11_item_list_4:'Fotografía',
        // seccion_11_item_list_5:'Decoración',
        // seccion_11_item_list_6:'Servicios especializados',
        // seccion_11_item_list_7:'Tramitación licencia turística',
        // seccion_11_item_list_8:'Asesoramiento para inversores',
        // seccion_12_titulo:'CHECKIN REMOTO',
        // seccion_12_descripcion_parte_1:'Para el acceso al edificio, a los diferentes recintos de su establecimiento hotelero, o hasta a la habitación, Linetor le proporciona una llave única virtual, temporal para la estancia reservada, intransferible y segura, con la que sus clientes y empleados pueden acceder usado simplemente su móvil, sin necesidad de instalar ninguna App, y en paralelo a sus sistemas tradicionales, es decir, dejando plenamente operativos sus sistemas actuales.',
        // seccion_12_descripcion_parte_2:'Gracias a los sistemas de acceso proporcionados por Linetor, el check-in se puede convertir en algo que sus clientes podrán realizar desde el móvil o desde un ordenador conectado a internet, y la llave virtual le llegará directamente a su mail, sin necesidad de pasar por recepción.',
      },
      cards_seccion_3: [
        // {id:1,
        // descripcion:'En LINETOR, te ofrecemos servicios de gestión de apartamentos turísticos en toda España. Si tienes un apartamento para alquilar por días, semanas o el periodo que desees y no tienes tiempo de gestionarlo, nosotros nos encargaremos de la higiene y confort del mismo.',
        // titulo: '¿Le gustaría dejar esta tarea en manos de profesionales?',
        // img: '/img/gestion.jpg',
        // tipo: 'gestion',
        // },
        // {id:2,
        // descripcion:'El actual flujo de huéspedes en régimen de alquiler turístico ha generado un estándar de exigencia que obliga a mantener todas las estancias de un apartamento en perfectas condiciones e higiene. Una vez abandonado el piso realizamos la limpieza y así se mantendrá siempre en las mejores condiciones.',
        // titulo: 'Limpieza de Apartamentos Turísticos.',
        // img: '/img/limpieza.jpg',
        // tipo: 'gestion',
        // },
        // {id:3,
        // descripcion:'NO BUSQUE MÁS…. Ofrezca a sus clientes un apartamento resplandeciente, por una empleada de limpieza especializada en apartamentos turísticos. Para su seguridad se asignará siempre la misma empleada.',
        // titulo: '¿Quería encargarse personalmente de ello, pero no dispone del tiempo?',
        // img: '/img/tiempo.jpg',
        // tipo: 'gestion',
        // }
      ],
      cards_seccion_5: [
        // {id:1,
        // descripcion:'Bienvenida de huéspedes. Nos ponemos en contacto con el cliente una vez realizada la reserva de su apartamento. Entrega de llaves a huéspedes, recogemos el alquiler y fianza, en caso de que usted no disponga del tiempo. Nos reunimos en el piso a la hora establecida y le mostramos el apartamento y explicamos las condiciones de la estadía. Tarifas calculadas en función de sus necesidades, ubicación y horario de entrega.',
        // titulo: 'Check in',
        // img: '/img/checkin.jpg',
        // tipo: 'checkin_checkout',
        // },
        // {id:2,
        // descripcion:'Recepción de las llaves al final de la estadía El día de la salida vamos al apartamento en la hora fijada. Revisamos el estado del apartamento y hacemos la entrega de la fianza. Tarifas calculadas en función de sus necesidades, ubicación y horario de entrega.',
        // titulo: 'Check out',
        // img: '/img/checkout.jpg',
        // tipo: 'checkin_checkout',
        // },
      ],
      cards_nuestros_servicios: [
        // {id: 1,
        // img: '',
        // titulo: 'SISTEMAS DE COLABORACION',
        // descripcion: 'Nos encargamos de la gestión integral de su propiedad',
        // button: 'Ver más',
        // seccion: 'sistem_colab'
        // },
        // {id: 2,
        // img: '',
        // titulo: 'SERVICIOS DE APOYO AL PROPIEATRIO ',
        // descripcion: 'Nos encargamos del Servicio de limpieza y lavandería, también nos encargamos del Servicio de entrega y recepción de llaves y de muchos servicios mas.',
        // button: 'Ver más',
        // seccion: 'serv_apoyo'
        // }
      ],
      fichas_gestion_seccion_2: [
        // {id:1, titulo: 'Mejora tus ingresos.', img: '/img/income.png',},
        // {id:2, titulo: 'Disfruta de tu tiempo.', img: '/img/time.png',},
        // {id:3, titulo: 'Ya no realices más gestiones.', img: '/img/resting.png',}
      ],
      refresh: 0,
    };
  },
  async mounted() {
    ///////////////
    
    this.$store.watch(
      (state) => [state.recarga].join(),
      async () => {
        // this.showItemsNumber();
        // this.refreshButton();
        this.textos = JSON.parse(localStorage.getItem("text"));
        await this.llenarCampos();
        //console.log(this.textos)
      }
    );
    this.textos = JSON.parse(localStorage.getItem("text"));
    await this.llenarCampos();
    console.log(this.fichas_gestion_seccion_2)
  },
  methods: {
    async llenarCampos() {
      this.cards_seccion_5 = [];
      this.fichas_gestion_seccion_2 = [];
      this.cards_seccion_3 = [];
      //this.textos = []
      // this.cards_nuestos_servicios.splice(0, this.cards_nuestos_servicios.length)
      this.cards_nuestros_servicios = [];
      this.cards_nuestros_servicios.splice(
        0,
        this.cards_nuestros_servicios.length
      );
      // llenando campos de la sección 2
      let card1 = {
        id: 1,
        titulo: this.textos.seccion_2_card1_titulo,
        img: this.textos.seccion_2_card1_img,
      };
      let card2 = {
        id: 2,
        titulo: this.textos.seccion_2_card2_titulo,
        img: this.textos.seccion_2_card2_img,
      };
      let card3 = {
        id: 3,
        titulo: this.textos.seccion_2_card3_titulo, //este campo no existe en la tabla
        img: this.textos.seccion_2_card3_img, //este campo no existe en la tabla
      };
      this.fichas_gestion_seccion_2.push(card1);
      this.fichas_gestion_seccion_2.push(card2);
      this.fichas_gestion_seccion_2.push(card3);

      // console.log('fichas gestion 2', this.fichas_gestion_seccion_2)

      // llenando campos de la sección 3
      let seccion_3_card1 = {
        id: 1,
        titulo: this.textos.seccion_3_card1_titulo,
        descripcion: this.textos.seccion_3_card1_descripcion,
        img: "/img/gestion.png", //this.textos.seccion_3_card1_img,
      };
      let seccion_3_card2 = {
        id: 2,
        titulo: this.textos.seccion_3_card2_titulo,
        descripcion: this.textos.seccion_3_card2_descripcion,
        img: "img/limpieza.png", //this.textos.seccion_3_card2_img,
      };
      let seccion_3_card3 = {
        id: 3,
        titulo: this.textos.seccion_3_card3_titulo,
        descripcion: this.textos.seccion_3_card3_descripcion,
        img: "img/tiempo.png", //this.textos.seccion_3_card3_img,
      };
      this.cards_seccion_3.push(seccion_3_card1);
      this.cards_seccion_3.push(seccion_3_card2);
      this.cards_seccion_3.push(seccion_3_card3);

      //console.log('cards seccion 3', this.cards_seccion_3)

      // llenando campos de la sección 5
      let seccion_5_card1 = {
        id: 1,
        titulo: this.textos.seccion_5_card1_titulo,
        descripcion: this.textos.seccion_5_card1_descripcion,
        img: "img/checkin.png", // this.textos.seccion_5_card1_img,
      };
      let seccion_5_card2 = {
        id: 2,
        titulo: this.textos.seccion_5_card2_titulo,
        descripcion: this.textos.seccion_5_card2_descripcion,
        img: "img/checkout.png", // this.textos.seccion_5_card2_img,
      };
      this.cards_seccion_5.push(seccion_5_card1);
      this.cards_seccion_5.push(seccion_5_card2);

      this.cards_nuestros_servicios = [
        {
          id: 1,
          img: "",
          titulo: this.textos.nuestros_servicios_card1_titulo, // 'SISTEMAS DE COLABORACION',
          descripcion: this.textos.nuestros_servicios_card1_descripcion, // 'Nos encargamos de la gestión integral de su propiedad',
          button: this.textos.nuestros_servicios_card1_texto_boton,
          seccion: "sistem_colab",
          ruta: "/sistem_colab",
        },
        {
          id: 2,
          img: "",
          titulo: this.textos.nuestros_servicios_card2_titulo,
          descripcion: this.textos.nuestros_servicios_card2_descripcion,
          button: this.textos.nuestros_servicios_card2_texto_boton,
          seccion: "serv_apoyo",
          ruta: "/servicios_propietario",
        },
      ];

      //console.log('cards seccion 3', this.cards_seccion_5)
    },
  },
};
</script>

<style scoped>
@import "../assets/css/styles-new.css";

.icon-mv {
  visibility: hidden !important;
  display: none !important;
  padding: 45px !important;
}
.banner_title{
    font-size: 3rem;
  }
#home {
  font-family: "Quicksand", sans-serif;
}
.text-position-div-5 {
  text-align: left !important;
}
.text-position-div-5-p {
  text-align: left !important;
}
.div1 {
  height: 100%;
}
.div2 {
  height: 100%;
}
.div3 {
  height: 100%;
}
.div4 {
  height: 100%;
}
.div5 {
  height: 100%;
}
.w-div-5 {
  width: 86% !important;
  margin-left: 7% !important;
}
.w-titulo-5 {
  width: 86% !important;
  margin-left: 7% !important;
}
.w-lista-5 {
  width: 85% !important;
  margin-left: 7% !important;
}
.titulo-reparacion {
  width: 66% !important;
  text-align: center !important;
  margin-left: 17%;
}
.parallax {
  display: flex;
 
  align-items: center;
  /* The image used */
  background-image: url("/img/1.jpg");

  /* Set a specific height */
  height: 460px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.div-a1 {
  /* padding-top: 100px; */
  width: 66% !important;
  text-align: center !important;
  margin-left: 17%;
}
.parallax2 {
  display: flex;


  /* The image used */
  background-image: url("/img/2.jpg");

  /* Set a specific height */
  height: 460px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.div-a2 {
  padding-top: 205px;
  width: 66% !important;
  text-align: center !important;
  margin-left: 17%;
}
.parallax3 {
  /* The image used */
  background-image: url("/img/3.jpg");

  /* Set a specific height */
  height: 460px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.div-a3 {
  padding-top: 190px;
  width: 50% !important;
  text-align: center !important;
  margin-left: 25%;
}
.parallax4 {
  /* The image used */
  background-image: url("/img/4.jpg");

  /* Set a specific height */
  height: 460px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.div-a4 {
  padding-top: 115px;
  width: 80% !important;
  text-align: center !important;
  margin-left: 10%;
}
.p-a4 {
  width: 56% !important;
  text-align: left !important;
  margin-left: 22%;
}
.parallax5 {
  /* The image used */
  /* background-image: url("/img/5.jpg"); */
  background-image: url("/img/5cli.png");

  /* Set a specific height */
  height: 460px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.div-a5 {
  padding-top: 150px;
  width: 80% !important;
  text-align: center !important;
  margin-left: 10%;
}
.p-a5 {
  width: 56% !important;
  text-align: left !important;
  margin-left: 22%;
}
.parallax6 {
  /* The image used */
  background-image: url("/img/7.png");

  /* Set a specific height */
  height: 460px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.div-a6 {
  padding-top: 120px;
  width: 80% !important;
  text-align: center !important;
  margin-left: 10%;
}
.p-a6 {
  width: 56% !important;
  text-align: left !important;
  margin-left: 22%;
}
#new-home {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: initial !important;
}

.parallax_nuestros_serv {
  /* The image used */
  /* background-image: url("/img/img6_blue.jpg"); */
  background-image: url("/img/img6.png");

  /* Set a specific height */
  height: 460px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.parallax_lavanderia {
  background-image: url("/img/6.jpg");

  /* Set a specific height */
  height: 460px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.serv_integral {
  background-image: url("/img/serv_integral.jpg");

  /* Set a specific height */
  height: 460px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.div_res_serv-inte_pc {
  display: block !important;
}
.div_res_serv-inte_movil {
  display: none !important;
}

.final-text-s7 {
  font-weight: bold;
  font-size: 0.9rem !important;
  margin-left: 0 auto;
  margin-right: 0 auto;
}


@media only screen and (max-width: 780px) {
  .w-lista-5 {
    width: 89% !important;
    margin-left: 1% !important;
  }
  .banner_title{
    font-size: 2rem;
  }
  .text-position-div-5 {
    text-align: center !important;
  }
  .text-position-div-5-p {
    text-align: justify !important;
  }
  .div1 {
    height: 100%;
  }
  .div2 {
    height: 100%;
  }
  .div3 {
    height: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
  .div4 {
    height: 100%;
  }
  .div5 {
    height: 100%;
  }
  .parallax {
    /* The image used */

    height: 460px;
  }
  .div-a1 {
    padding-top: 170px;
    width: 80% !important;
    text-align: center !important;
    margin-left: 10%;
  }
  .parallax2 {
    /* The image used */

    /* Set a specific height */
    height: 460px;
  }
  .div-a2 {
    padding-top: 205px;
    width: 66% !important;
    text-align: center !important;
    margin-left: 17%;
  }
  .parallax3 {
    /* The image used */

    /* Set a specific height */
    height: 460px;

    /* Create the parallax scrolling effect */
  }
  .div-a3 {
    padding-top: 205px;
    width: 80% !important;
    text-align: center !important;
    margin-left: 10%;
  }
  .parallax4 {
    /* The image used */

    /* Set a specific height */
    height: 560px;

    /* Create the parallax scrolling effect */
  }
  .div-a4 {
    padding-top: 50px;
    width: 90% !important;
    text-align: center !important;
    margin-left: 5%;
  }
  .p-a4 {
    width: 70% !important;
    text-align: justify !important;
    margin-left: 15%;
  }
  .parallax5 {
    /* The image used */
    background-image: url("/img/5.jpg");

    /* Set a specific height */
    height: 470px;

    /* Create the parallax scrolling effect */
  }
  .div-a5 {
    padding-top: 50px;
    width: 90% !important;
    text-align: center !important;
    margin-left: 5%;
  }
  .p-a5 {
    width: 70% !important;
    text-align: justify !important;
    margin-left: 15%;
  }
  .parallax6 {
    /* The image used */
    height: 460px;
  }
  .div-a6 {
    padding-top: 50px;
    width: 80% !important;
    text-align: center !important;
    margin-left: 10%;
  }
  .p-a6 {
    width: 70% !important;
    text-align: justify !important;
    margin-left: 15%;
  }
  .parallax_nuestros_serv {
    /* The image used */

    /* Set a specific height */
    height: 460px;
  }
  .parallax_lavanderia {
    /* The image used */

    /* Set a specific height */
    height: 460px;
  }
  .serv_integral {
    height: 460px;
  }
 .banner_title{
  padding: 0;
 }
}
@media only screen and (max-width: 1350px) and (min-width: 780px) {
  .div-a6 {
    padding-top: 85px;
    width: 80% !important;
    text-align: center !important;
    margin-left: 10%;
  }
  .p-a6 {
    width: 70% !important;
    text-align: left !important;
    margin-left: 15%;
    margin-bottom: 0% !important;
  }
  .div-a4 {
    padding-top: 85px;
    width: 90% !important;
    text-align: center !important;
    margin-left: 5%;
  }
  .p-a4 {
    width: 70% !important;
    text-align: left !important;
    margin-left: 15%;
  }
}
@media only screen and (max-width: 1058px) {
  .div_res_serv-inte_pc {
    display: none !important;
  }
  .div_res_serv-inte_movil {
    display: block !important;
  }


  .icon-mv {
    padding: 15px !important ;
    visibility: visible !important;
    display: initial !important;
  }

}


@media only screen and (max-width: 1060px) {
  .icon-mv {
    visibility: visible !important;
    display: initial !important;
    padding: 45px !important ;

  }
  
}

@media only screen and (max-width: 660px) {
  .icon-mv {
    padding: 15px !important ;
    visibility: visible !important;
    display: initial !important;
  }
}
@media (max-width: 480px) {

  .padding_0{
    padding: 0 !important;
  }
 .div3 .container{ 
  padding: 0 !important;
 }
 .div4 .container{
  padding: 0 !important;
 }
 .div_res_serv-inte_movil{
  padding: 0 !important;
 }
  #beneficios p {
    text-align:justify;
  }
  p{
    text-align: justify !important;
  }
}
</style>
